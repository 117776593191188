import {
	SiteFragmentFragment,
	type AuthSettings,
	type TeamFragmentFragment,
	type User,
} from '@apps/www/src/__generated__/graphql';
import SVGridAddButtonContainer from '@apps/www/src/www/containers/SVGridAddButtonContainer';
import SVNavSearchContainer from '@apps/www/src/www/containers/SVNavSearchContainer';
import type SVNotificationsIconContainer from '@apps/www/src/www/containers/SVNotificationsIconContainer';
import SVSubscriptionWarningContainer from '@apps/www/src/www/containers/SVSubscriptionWarningContainer';
import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import useAllowedToSeeNewSearch from '@apps/www/src/www/hooks/useAllowedToSeeNewSearch';
import SVA from '@pkgs/shared-client/components/SVA';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVAvatar from '@pkgs/shared-client/components/SVAvatar';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import SVRetractableBar from '@pkgs/shared-client/components/SVRetractableBar';
import config from '@pkgs/shared-client/config';
import formatCount from '@pkgs/shared-client/helpers/formatCount';
import matchLocation from '@pkgs/shared-client/helpers/matchLocation';
import IconMenuSVG from '@pkgs/shared-client/img/arrow-menu-inlined.svg';
import ArrowRightToTop from '@pkgs/shared-client/img/arrow-right-to-top-inlined.svg';
import IconAddSmallSVG from '@pkgs/shared-client/img/icon-add-small-inlined.svg';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import iconDarkMode from '@pkgs/shared-client/img/icon-dark-mode-inlined.svg';
import IconHappyFaceSVG from '@pkgs/shared-client/img/icon-happy-face-inlined.svg';
import IconLightMode from '@pkgs/shared-client/img/icon-light-mode-inlined.svg';
import IconSearchMenuSVG from '@pkgs/shared-client/img/icon-search-menu-inlined.svg';
import IconSmallSelectSVG from '@pkgs/shared-client/img/icon-select-small-inlined.svg';
import IconSettingsSVG from '@pkgs/shared-client/img/icon-settings-inlined.svg';
import IconSiteMakerSVG from '@pkgs/shared-client/img/icon-site-maker-inlined.svg';
import IconSliderSmallSVG from '@pkgs/shared-client/img/icon-slider-small-inlined.svg';
import IconTeamSVG from '@pkgs/shared-client/img/icon-team-inlined.svg';
import LogoSVG from '@pkgs/shared-client/img/logo-inlined.svg';
import IconLogoSVG from '@pkgs/shared-client/img/logo-s-menu-inlined.svg';
import { unit } from '@pkgs/shared-client/styles/mixins';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import clsx from 'clsx';
import { NextRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

const _TopSpacer = React.forwardRef<HTMLDivElement>((props, ref) => (
	<div ref={ref} className="h-nav-height" {...props} />
));

type MenuItemsProps = {
	children: React.ReactNode;
	className?: string;
	isLoading?: boolean;
	target?: string;
	onClick?: (event: React.UIEvent<Element, UIEvent>) => void;
	to?: Parameters<NextRouter['push']>[0];
};

const _MenuItems = React.memo(({ children, ...props }: MenuItemsProps) => {
	const { onClick, ...adjustedProps } = props;

	if (onClick) {
		return (
			<SVDropdownContent.Links.Item {...adjustedProps} onClick={onClick} isOnMenu>
				{children}
			</SVDropdownContent.Links.Item>
		);
	}

	return (
		// @ts-expect-error
		<SVDropdownContent.Links.Item {...adjustedProps} Component={SVLink} isOnMenu>
			{children}
		</SVDropdownContent.Links.Item>
	);
});

const _LinkSection = React.memo(({ children }: OnlyChildrenProps) => (
	<div className="flex flex-col px-4">{children}</div>
));

const _NavLogo = React.memo(
	({ isEditing, locationPath, team }: Pick<Props, 'isEditing' | 'locationPath' | 'team'>) => {
		const isCurrent =
			isEditing || matchLocation(locationPath, team?.teamFeedAsHomepage ? '/feed' : '/');

		return (
			<ul>
				<SVIconButton
					src={LogoSVG}
					reversedCurrentStyle={true}
					Component={SVLink}
					to={team?.teamFeedAsHomepage ? '/feed' : '/'}
					title="Home"
					iconClassName={clsx(
						'min-h-[24px] w-[24px] h-[24px] min-w-[24px]',
						!isCurrent && 'text-gray-400',
					)}
					isCurrent={isCurrent}
					disableHover
				/>
			</ul>
		);
	},
);

const _NavUpgradeButton = React.memo(
	({
		userHasExpiredSubscription,
		userItemsCount,
	}: Pick<Props, 'userHasExpiredSubscription' | 'userItemsCount'>) => {
		const userItemsCountOrZero = userItemsCount || 0;
		const itemsLimit = config.payment.maxFreeItems;
		const statsText = `You have ${formatCount(
			userItemsCountOrZero,
		)} saves out of your free limit of ${formatCount(itemsLimit)}.`;

		let progressBar: React.ReactNode = null;
		if (userItemsCountOrZero > 0) {
			const ratio = Math.min(1, Math.max(0, userItemsCountOrZero / itemsLimit));

			progressBar = (
				<div className="hidden min-w-[90px] items-center space-y-2 md:flex md:flex-col xl:hidden 2xl:flex">
					<div className="type-label text-primary/75 w-full text-[10px]">
						Saved {formatCount(userItemsCountOrZero)} of {formatCount(itemsLimit)} saves
					</div>
					<div className="bg-primary/20 relative h-[2px] w-full flex-grow overflow-hidden rounded">
						<div
							className={clsx(
								'absolute left-0 top-0 h-full',
								ratio >= 0.9 ? 'bg-danger' : 'bg-brand',
							)}
							style={{ width: unit(Math.round(ratio * 100), '%') }}
						/>
					</div>
				</div>
			);
		}

		return (
			<li>
				<div className="relative flex items-center space-x-6">
					{progressBar}
					<SVTrackedUpgradeButtonContainer
						source={UpgradeButtonSource.NAV_BAR}
						size={SVButtonSIZES.SMALL}
						className="type-label px-4 py-3 font-semibold"
						to="/upgrade/"
						title={
							userHasExpiredSubscription
								? `Restart Premium\n\n${statsText}`
								: `Upgrade\n\n${statsText}`
						}
					>
						{userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'}
					</SVTrackedUpgradeButtonContainer>
				</div>
			</li>
		);
	},
);

const _NavItemLink = React.memo(
	({
		to,
		label,
		Icon,
		side,
		className,
	}: {
		to: string;
		label: string;
		Icon: React.ComponentType;
		side: boolean;
		className?: string;
	}) => (
		<_MenuItems to={to}>
			{side ? (
				<div className="flex w-full items-center space-x-2 pr-1">
					<div className={className}>{label}</div>
					<SVFlexSpacer />
					<div className="text-gray-500">
						<Icon />
					</div>
				</div>
			) : (
				<>
					<div className="mr-2 flex w-[28px] items-center justify-center text-gray-500">
						<Icon />
					</div>
					<div className={className}>{label}</div>
				</>
			)}
		</_MenuItems>
	),
);

const _NavItemLinkAction = React.memo(
	({
		label,
		Icon,
		onClick,
	}: {
		label: string;
		Icon: React.ComponentType;
		onClick: (event: React.UIEvent<Element, UIEvent>) => void;
	}) => (
		<_MenuItems onClick={onClick}>
			<div className="mr-2 flex w-[28px] items-center justify-center text-gray-500">
				<Icon />
			</div>
			{label}
		</_MenuItems>
	),
);

const _NavAvatarIcon = React.memo(
	({
		userURL,
		userName,
		userAvatarURL,
		onToggleDarkMode,
		isDarkMode,
		userEmail,
		team,
		unreadNotificationsCount,
		onToggleEditing,
		showGridControls,
		NotificationsComponent,
		onOpenGridSettings,
		site,
		locationPath,
		userHasExpiredSubscription,
		userHasProPrivileges,
	}: Pick<
		Props,
		| 'userURL'
		| 'userName'
		| 'userAvatarURL'
		| 'onToggleDarkMode'
		| 'isDarkMode'
		| 'userEmail'
		| 'team'
		| 'unreadNotificationsCount'
		| 'onToggleEditing'
		| 'showGridControls'
		| 'NotificationsComponent'
		| 'onOpenGridSettings'
		| 'site'
		| 'locationPath'
		| 'userHasExpiredSubscription'
		| 'userHasProPrivileges'
	>) => (
		<ul>
			<SVDropdown
				positionStrategy={SVDropdown.POSITION_STRATEGIES.FIXED}
				triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
				contentOffset="h-4"
				renderTrigger={({ isOpen, ...props }) => (
					<div className="flex items-center space-x-2">
						<SVA
							Component={SVLink}
							to={userURL}
							title="View your saves"
							className="flex items-center"
						>
							<div
								className={clsx(
									'relative',
									matchLocation(locationPath, `${userURL}**`) &&
										'ring-brand rounded-full p-0.5 ring-2',
								)}
							>
								<SVAvatar className="h-[28px] w-[28px]" src={userAvatarURL} />
							</div>
						</SVA>

						<SVIconButton
							Component="button"
							src={IconMenuSVG}
							iconClassName="min-h-[13px] min-w-[13px] text-gray-400"
							onGrid="h-[25px] min-h-[25px] w-[25px] min-w-[25px]"
							{...props}
						/>
					</div>
				)}
				renderContent={() => (
					<div className="z-index-nav -md:min-w-[220px] min-w-[260px] py-5">
						<_LinkSection>
							<div className="mx-2">
								<SVDropdownContent.Links.ItemLinkMain
									to={userURL}
									label={userEmail}
									disableHover
								>
									<div className="-mb-1">{userName}</div>
								</SVDropdownContent.Links.ItemLinkMain>
							</div>
							{userHasExpiredSubscription ||
								(!userHasProPrivileges && (
									<div className="-lg:block hidden">
										<SVButton
											className="mt-2 w-full"
											size={SVButtonSIZES.EXTRA_SMALL}
											use={SVButtonUSES.PRIMARY}
											to="/upgrade/"
										>
											{userHasExpiredSubscription
												? 'Restart Premium'
												: 'Upgrade'}
										</SVButton>
									</div>
								))}
							<SVDropdownContent.Links.Separator />
							<_NavItemLink
								to="/profile/"
								label="Settings"
								Icon={IconSettingsSVG}
								side={false}
							/>
							<_NavItemLink
								to="/site-maker/edit/"
								label={site ? 'My site' : 'Create your site'}
								Icon={IconSiteMakerSVG}
								side={false}
							/>
							<_NavItemLink
								to={team ? `/team/users/` : '/team/'}
								label={team ? 'Team' : 'Start a team'}
								Icon={IconTeamSVG}
								side={false}
							/>
							<_NavItemLink
								to="/extension/"
								label="Get Extension"
								Icon={IconHappyFaceSVG}
								side={false}
							/>
							{onToggleDarkMode && (
								<_NavItemLinkAction
									onClick={onToggleDarkMode}
									Icon={isDarkMode ? IconLightMode : iconDarkMode}
									label={isDarkMode ? 'Light Mode' : 'Dark Mode'}
								/>
							)}
							<div className="-md:block relative hidden">
								{NotificationsComponent && <NotificationsComponent showLabel />}
							</div>
							{showGridControls && (
								<_NavItemLinkAction
									onClick={onOpenGridSettings}
									label="Customize Grid"
									Icon={IconSliderSmallSVG}
								/>
							)}

							{showGridControls && (
								<_NavItemLinkAction
									onClick={onToggleEditing}
									label="Multi select"
									Icon={IconSmallSelectSVG}
								/>
							)}
							<SVDropdownContent.Links.Separator />
							<_NavItemLink
								to="https://inspire.savee.it/"
								label="Blog"
								Icon={ArrowRightToTop}
								side
								className="pl-1"
							/>
							<_NavItemLink
								to="/marketplace/"
								label="Marketplace"
								Icon={ArrowRightToTop}
								side
								className="pl-1"
							/>
							<_NavItemLink
								to="/course/"
								className="pl-1"
								label="Learn"
								side
								Icon={ArrowRightToTop}
							/>
							<_MenuItems to="/contact-us/" className="pl-1">
								Contact us
							</_MenuItems>
							<_MenuItems to="/logout/" className="pl-1">
								Log out
							</_MenuItems>

							<div className="mx-2 flex justify-between pt-10 text-sm text-gray-900">
								<SVA
									Component={SVLink}
									to="/privacy/"
									className="text-gray-400 hover:text-gray-500"
								>
									Privacy
								</SVA>
								<SVA
									Component={SVLink}
									to="/copyright-policy/"
									className="text-gray-400 hover:text-gray-500"
								>
									Copyrights
								</SVA>
								<SVA
									Component={SVLink}
									to="/terms/"
									className="text-gray-400 hover:text-gray-500"
								>
									Terms
								</SVA>
							</div>
						</_LinkSection>
					</div>
				)}
			/>
		</ul>
	),
);

const _DefaultNavItems = React.memo(
	({
		locationPath,
		items,
	}: {
		locationPath: string;
		items: { to: string; label: string; matchUrl?: string; target?: string }[];
	}) => {
		const navgateToTop = (e, item) => {
			const itemPath = item.to;

			const matchPattern = item.matchUrl || `${itemPath}/**`;

			if (matchLocation(locationPath, matchPattern)) {
				if (e) e.preventDefault();

				if (matchLocation(locationPath, itemPath)) {
					window.scrollTo({ top: 0, behavior: 'smooth' });
					return;
				}

				window.location.href = itemPath;
				return;
			}
		};

		const subnavItems = items.map((item) => (
			<SVActions.NavItem
				key={item.to}
				to={item.to}
				matchURL={item.matchUrl}
				target={item.target}
			>
				<div
					className="whitespace-no-wrap max-w-[12vw] cursor-pointer truncate"
					onClick={(e) => navgateToTop(e, item)}
				>
					{item.label}
				</div>
			</SVActions.NavItem>
		));

		return <SVActions use={SVActions.USES.FEEDNAV}>{subnavItems}</SVActions>;
	},
);

const _Nav = ({
	isDarkMode,
	isEditing,
	isLogged,
	// isNavOpen,
	locationPath,
	// onCloseNav,
	onOpenGridSettings,
	// onOpenNav,
	// onOpenOnboard,
	// onSelectFiles,
	onToggleDarkMode,
	onToggleEditing,
	showUpgrade,
	userAvatarURL,
	userHasProPrivileges,
	userHasEnterprisePrivileges,
	userHasExpiredSubscription,
	NotificationsComponent,
	showGridControls,
	userURL,
	userName,
	userItemsCount,
	userEmail,
	unreadNotificationsCount,
	onHeightChange,
	team,
	site,
}: Omit<Props, 'isSmallNav'>) => {
	const divRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (typeof ResizeObserver === 'undefined') {
			return;
		}

		const handleResize = () => {
			onHeightChange && onHeightChange(divRef.current?.clientHeight || 0);
		};

		const resizeObserver = new ResizeObserver(handleResize);

		if (divRef.current) {
			resizeObserver.observe(divRef.current);
		}

		const currentRef = divRef.current;

		return () => {
			if (currentRef) {
				resizeObserver.unobserve(currentRef);
			}
		};
	}, [onHeightChange]);

	const isAllowedToSeeNewSearch = useAllowedToSeeNewSearch();

	const leftActionsItems: {
		to: string;
		label: string;
		matchUrl?: string;
	}[] = [
		team && {
			to: team.teamFeedAsHomepage ? '/' : '/team/',
			label: team.name,
			matchUrl: '/team/**',
		},
		isAllowedToSeeNewSearch
			? {
					to: '/explore/',
					label: 'Explore',
			  }
			: {
					to: '/search/',
					label: 'Search',
					matchUrl: '/search/**',
			  },
	].filter(Boolean);

	const rightActionsItems: {
		to: string;
		label: string;
		matchUrl?: string;
		target?: string;
	}[] = [
		{
			to: userHasEnterprisePrivileges ? '/marketplace/' : `https://marketplace.savee.it/`,
			label: 'Marketplace',
			target: userHasEnterprisePrivileges ? '_self' : '_blank',
		},
		{ to: '/course/', label: 'Course', matchUrl: '/course/**' },
	];

	return (
		<div ref={divRef}>
			<SVSubscriptionWarningContainer />
			<div className="h-nav-height text-secondary relative flex items-center">
				<div className="duration-slide bg-background absolute inset-0 h-full w-full transition-colors ease-in-out" />
				<SVPageMargins className="flex-grow">
					{isAllowedToSeeNewSearch ? (
						<div className="pointer-events-none absolute inset-0 flex items-center justify-center">
							<div className="pointer-events-auto">
								<SVNavSearchContainer />
							</div>
						</div>
					) : null}
					<ul className="flex items-center space-x-6">
						<_NavLogo isEditing={isEditing} locationPath={locationPath} team={team} />
						{isLogged ? (
							<_DefaultNavItems
								items={leftActionsItems}
								locationPath={locationPath}
							/>
						) : null}
						<SVFlexSpacer />
						{isLogged &&
							showUpgrade &&
							!userHasExpiredSubscription &&
							!userHasProPrivileges && (
								<div className="-lg:hidden block">
									<_NavUpgradeButton
										userHasExpiredSubscription={userHasExpiredSubscription}
										userItemsCount={userItemsCount}
									/>
								</div>
							)}
						{isLogged ? (
							<div className="-lg:hidden block">
								<_DefaultNavItems
									items={rightActionsItems}
									locationPath={locationPath}
								/>
							</div>
						) : null}
						<li>
							<ul className="flex items-center space-x-6">
								<div className="flex items-center space-x-6 text-gray-400">
									<div>
										{isLogged && (
											<SVGridAddButtonContainer iconClassName="text-gray-400" />
										)}
									</div>

									{NotificationsComponent && (
										<li className="relative">
											<NotificationsComponent />
										</li>
									)}
								</div>

								{isLogged && (
									<_NavAvatarIcon
										userURL={userURL}
										userName={userName}
										userAvatarURL={userAvatarURL}
										onToggleDarkMode={onToggleDarkMode}
										isDarkMode={isDarkMode}
										userEmail={userEmail}
										team={team}
										unreadNotificationsCount={unreadNotificationsCount}
										onToggleEditing={onToggleEditing}
										showGridControls={showGridControls}
										NotificationsComponent={NotificationsComponent}
										onOpenGridSettings={onOpenGridSettings}
										site={site}
										locationPath={locationPath}
										userHasExpiredSubscription={userHasExpiredSubscription}
										userHasProPrivileges={userHasProPrivileges}
									/>
								)}
								{!isLogged && (
									<li className="color-white z-10 ml-3">
										<SVA Component={SVLink} to="/join/">
											Join
										</SVA>{' '}
										/{' '}
										<SVA Component={SVLink} to="/login/">
											Log In
										</SVA>
									</li>
								)}
							</ul>
						</li>
					</ul>
				</SVPageMargins>
			</div>
		</div>
	);
};

// TODO: Fix bug when viewportName changes (this doesn't get rendered, for some reason) still a bug?
const _SmallNav = ({
	isDarkMode,
	// isEditing,
	// showGridControls,
	isLogged,
	isNavOpen,
	locationPath,
	onCloseNav,
	// onOpenGridSettings,
	onOpenGridUpload,
	onOpenNav,
	// onOpenOnboard,
	// onSelectFiles,
	onToggleDarkMode,
	NotificationsComponent,
	// onToggleEditing,
	// showUpgrade,
	// userAvatarURL,
	userCanSeeBillingPage,
	userHasExpiredSubscription,
	userURL,
	team,
	site,
	userAvatarURL,
	userName,
}: Omit<Props, 'isSmallNav'>) => {
	const [selected, setSelected] = useState(0);

	const handleSelect = (index: number) => {
		setSelected(index);
	};

	const routes = ['/', '/search/**', '/notifications', userURL];

	useEffect(() => {
		routes.map((route, index) => {
			if (matchLocation(locationPath, route)) {
				setSelected(index > 1 ? index + 1 : index);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ul className="relative flex h-full w-full items-center justify-center ">
				<div className="from-background absolute inset-0 w-full bg-gradient-to-t to-transparent" />

				<div className="theme-dark relative m-6 mb-8 flex h-[60px] w-[256px] items-center justify-evenly rounded-full bg-white bg-opacity-10 p-1 shadow-xl backdrop-blur-xl">
					<div
						className={clsx(
							'absolute left-[7px] rounded-full bg-gray-50 transition-transform duration-300 ease-in-out ',
							selected === 4 ? 'h-8 w-8' : 'h-11 w-11',
						)}
						style={{
							transform: `translateX(${selected * 50.5}px)`,
						}}
					/>

					<div className="flex w-11 justify-center" onClick={() => handleSelect(0)}>
						<SVIconButton
							src={IconLogoSVG}
							reversedCurrentStyle={true}
							Component={SVLink}
							to="/"
							title="Home"
							className={selected === 0 ? 'text-gray-900' : 'text-gray-300'}
							disableHover
						/>
					</div>

					<div className="z-10 flex w-11 justify-center" onClick={() => handleSelect(1)}>
						<SVIconButton
							Component={SVLink}
							src={IconSearchMenuSVG}
							to="/search/"
							label="Search"
							className={
								selected === 1 ? 'text-gray-800' : 'stroke-width="2" text-gray-300'
							}
							iconClassName="w-full"
						/>
					</div>

					<div className="flex w-11 justify-center" onClick={() => handleSelect(2)}>
						{isLogged && (
							<SVGridAddButtonContainer
								iconClassName={selected === 2 ? 'text-gray-800' : 'text-gray-300'}
								isSmallNav={true}
							/>
						)}
					</div>

					<div className="z-10 -ml-[4.25px] flex justify-center pl-1">
						{NotificationsComponent &&
							NotificationsComponent({
								showLabel: false,
								iconClassName: 'text-gray-300 ',
								isSmallNav: true,
							})}
					</div>

					<div className="flex w-11 justify-center " onClick={() => handleSelect(4)}>
						<SVA Component={SVLink} to={userURL}>
							<SVAvatar src={userAvatarURL} title={userName} className="h-7 w-7" />
						</SVA>
					</div>
				</div>
			</ul>
			<SVRetractableBar
				position={SVRetractableBar.POSITIONS.BOTTOM}
				isOpen={isLogged && isNavOpen}
				onClose={onCloseNav}
				render={() => (
					<_SmallNavBottom
						onCloseNav={onCloseNav}
						userCanSeeBillingPage={userCanSeeBillingPage}
						userHasExpiredSubscription={userHasExpiredSubscription}
						userURL={userURL}
						onToggleDarkMode={onToggleDarkMode}
						isDarkMode={isDarkMode}
						onOpenGridUpload={onOpenGridUpload}
						NotificationsComponent={NotificationsComponent}
						team={team}
						site={site}
					/>
				)}
			/>
		</>
	);
};

const _SmallNavItemLink = ({
	children,
	link,
	onClick,
	Icon,
	noIcon,
}: {
	children: React.ReactNode;
	link?: string;
	onClick?: (event: React.UIEvent) => void;
	Icon: React.ComponentType<any>;
	noIcon?: boolean;
}) => (
	<_MenuItems to={link} onClick={onClick}>
		{!noIcon && <div className="mr-3 text-xl">{Icon && <Icon width="22" height="22" />}</div>}
		{children}
	</_MenuItems>
);

const _SmallNavItemExternalLink = ({
	children,
	link,
	useIcon,
	Icon,
	onClick,
}: {
	children: React.ReactNode;
	link: string;
	useIcon: boolean;
	onClick?: (event: React.UIEvent) => void;
	Icon?: React.ComponentType;
}) => (
	<_MenuItems to={link} target="_blank" onClick={onClick}>
		{children}
		{useIcon && <div className="ml-3">{Icon && <Icon />}</div>}
	</_MenuItems>
);

const _SmallNavBottom = React.memo(
	({
		onCloseNav,
		userHasExpiredSubscription,
		userCanSeeBillingPage,
		onToggleDarkMode,
		isDarkMode,
		onOpenGridUpload,
		NotificationsComponent,
		team,
		site,
	}: Pick<
		Props,
		| 'onCloseNav'
		| 'userURL'
		| 'userCanSeeBillingPage'
		| 'userHasExpiredSubscription'
		| 'isDarkMode'
		| 'onToggleDarkMode'
		| 'onOpenGridUpload'
		| 'NotificationsComponent'
		| 'team'
		| 'site'
	>) => {
		return (
			<>
				<ul className="box-border flex h-full w-full flex-col justify-center overflow-y-auto rounded-t-3xl bg-gray-900 bg-opacity-80 px-6 pt-12 text-2xl backdrop-blur-xl">
					<SVPageMargins className="mb-12 flex-grow">
						<SVIconButton
							className="absolute right-4 top-4"
							src={IconCloseSVG}
							onClick={onCloseNav}
						/>

						{!userCanSeeBillingPage || userHasExpiredSubscription ? (
							<SVButton
								className="mb-4 h-12 w-full text-xl"
								Component={SVLink}
								to={userHasExpiredSubscription ? '/billing/' : '/upgrade/'}
								title={userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'}
								size={SVButtonSIZES.EXTRA_SMALL}
							>
								{userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'}
							</SVButton>
						) : null}

						<_SmallNavItemLink link="/profile/" Icon={IconSettingsSVG}>
							Settings
						</_SmallNavItemLink>
						<_SmallNavItemLink link="/site-maker/edit/" Icon={IconSiteMakerSVG}>
							{site ? 'My site' : 'Create your site'}
						</_SmallNavItemLink>
						<_SmallNavItemLink
							link={team ? `/team/users/` : '/team/'}
							Icon={IconTeamSVG}
						>
							{team ? 'Team' : 'Start a team'}
						</_SmallNavItemLink>
						{NotificationsComponent && <NotificationsComponent showLabel />}
						<SVDropdownContent.Links.Separator />
						{onToggleDarkMode && (
							<_SmallNavItemLink
								onClick={onToggleDarkMode}
								Icon={isDarkMode ? IconLightMode : iconDarkMode}
							>
								{isDarkMode ? 'Light Mode' : 'Dark Mode'}
							</_SmallNavItemLink>
						)}

						<_SmallNavItemLink
							link="/"
							onClick={onOpenGridUpload}
							Icon={IconAddSmallSVG}
						>
							Upload
						</_SmallNavItemLink>
						<SVDropdownContent.Links.Separator />
						<_SmallNavItemExternalLink
							link="https://inspire.savee.it/"
							Icon={ArrowRightToTop}
							useIcon
						>
							Blog
						</_SmallNavItemExternalLink>

						<_SmallNavItemExternalLink
							link="https://marketplace.savee.it/"
							Icon={ArrowRightToTop}
							useIcon
						>
							Store
						</_SmallNavItemExternalLink>

						<_SmallNavItemExternalLink link="/course/" Icon={ArrowRightToTop} useIcon>
							Learn
						</_SmallNavItemExternalLink>

						<_SmallNavItemLink link="/contact-us/" Icon={ArrowRightToTop} noIcon>
							Contact us
						</_SmallNavItemLink>

						<_SmallNavItemLink link="/logout/" Icon={ArrowRightToTop} noIcon>
							Log out
						</_SmallNavItemLink>
					</SVPageMargins>
				</ul>
			</>
		);
	},
);

type Props = {
	isSmallNav: boolean;
	isDarkMode: AuthSettings['darkMode'];
	isEditing: boolean;
	showGridControls: boolean;
	isLogged: boolean;
	isAtTop: boolean;
	isNavOpen: boolean;
	locationPath: string;
	onCloseNav: (event: React.UIEvent) => void;
	onOpenGridSettings: (event: React.UIEvent) => void;
	onOpenGridUpload: (event: React.UIEvent) => void;
	onOpenNav: (event: React.UIEvent) => void;
	onNewBoardClick: (event: React.UIEvent) => void;
	onOpenOnboard: (event: React.UIEvent) => void;
	onSelectFiles: (files: FileList) => void;
	onToggleDarkMode?: (event: React.UIEvent) => void;
	onToggleEditing: (event: React.UIEvent) => void;
	showUpgrade: boolean;
	userAvatarURL: User['avatarURL'] | null;
	userCanSeeBillingPage: boolean;
	userHasProPrivileges: boolean;
	userHasEnterprisePrivileges: boolean;
	userHasExpiredSubscription: boolean;
	userURL: User['url'];
	userName: User['name'];
	userItemsCount: User['itemsCount'] | null;
	NotificationsComponent?: typeof SVNotificationsIconContainer;
	team?: TeamFragmentFragment | null;
	site: SiteFragmentFragment | null;
	userEmail: AuthSettings['email'];
	unreadNotificationsCount: AuthSettings['unreadNotificationsCount'];
	onHeightChange: (height: number) => void;
};

const SVNav = ({ isSmallNav, ...otherProps }: Props) => {
	if (isSmallNav) {
		return <_SmallNav {...otherProps} />;
	}

	return <_Nav {...otherProps} />;
};

SVNav._SmallNavBottom = _SmallNavBottom; // For tests only

SVNav.TopSpacer = _TopSpacer;

SVNav.Avatar = _NavAvatarIcon;

SVNav.NavLogo = _NavLogo;

export default SVNav;
